import React from "react";
import { useNavigate } from "react-router-dom";

const ReadyToPartner = () => {
  const handleClick = () => {
    window.open("/contact-us", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="partners-hero">
      <div className="partners-content">
        <h1 className="partners-title">Ready to Partner with Us?</h1>
        <p className="partners-description">
          Join Lipaworld today and reach new customers while growing your brand
          globally.
        </p>
        <button className="partners-button" onClick={handleClick}>
          Join Now
        </button>
      </div>
    </div>
  );
};

export default ReadyToPartner;
