import React from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import Related from "../../components/blog/Related";
import Footer from "../../components/Footer";

const VentureCrush = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/blog");
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <div className="foster-container">
            <div className="button-main2" onClick={handleBack}>
              <img
                src="/img/blog/pages/arrow-left.png"
                alt="Back"
                className="arrow-left"
              />
              <span className="back-text">Back</span>
            </div>

            <div className="image-section2">
              <img
                src="/img/blog/ventureCrush.png"
                alt="Venture Crush"
                className="community-image"
              />
            </div>

            <div className="content-section2">
              <div className="metadata2">
                <span>Press Release</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>10min read</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>July 25, 2024</span>
              </div>

              <h1 className="title2">Lipaworld Accepted into Prestigious VentureCrushFG Pod Program</h1>

              <p className="description2">
              <br />
                    <br />
                    Lipaworld, a leading innovator in digital financial solutions, is thrilled to announce its
                    acceptance into the VentureCrushFG Pod program. This significant milestone marks a pivotal step in
                    Lipaworld&apos;s journey toward expanding its partnership and investor network.
                    <br />
                    <br />
                    The VentureCrushFG Pod program, renowned for its comprehensive support of emerging startups, offers
                    a unique blend of virtual and in-person sessions across key locations in California, Washington DC,
                    and New York. Each Pod features 10-15 promising startups and 4-5 Pod leads, including top-tier
                    venture capitalists and members of the esteemed Lowenstein Sandler VentureCrush team.
                    <br />
                    <br />
                    &quot;Being part of the VentureCrushFG Pod program is a tremendous opportunity for Lipaworld,&quot;
                    said Jonathan Katende, Co-Founder and CEO at Lipaworld. &quot;This platform will enable us to
                    connect with a diverse network of investors and partners, providing invaluable insights and support
                    as we continue to scale our operations and refine our market strategy.&quot;
                    <br />
                    <br />
                    Throughout the program, Lipaworld will engage in both mandatory off-the-record Pod sessions and
                    larger on-the-record VentureCrushFG events. These events, held at the VentureCrushFG office in New
                    York and accessible via Zoom, will cover essential themes such as fundraising, recruitment, company
                    culture, and scaling. The subsequent Pod sessions will facilitate in-depth discussions and provide
                    tailored advice on the specific challenges faced by Lipaworld&apos;s co-founders.
                    <br />
                    <br />
                    VentureCrushFG is committed to fostering an inclusive and supportive environment, embracing
                    #diversityequityinclusion. The program does not take equity, charge fees, or require participants to
                    conduct business with one another. It also does not promote competition between founders or mandate
                    a demo day, ensuring that startups can focus on their unique growth trajectories without undue
                    pressure.
                    <br />
                    <br />
                    &quot;We are honored to be part of a program that values collaboration over competition,&quot; added
                    Mr Katende. &quot;The VentureCrushFG Pod program&apos;s ethos aligns perfectly with our vision at
                    Lipaworld, and we are excited to leverage this opportunity to strengthen our partnerships and
                    attract strategic investors.&quot;
                    <br />
                    <br />
                    Lipaworld looks forward to the transformative journey ahead with VentureCrushFG, confident that this
                    collaboration will significantly enhance its growth and impact in the fintech industry.
                    <br />
                    <br />
                    For more information about Lipaworld and its innovative financial solutions, please visit{' '}
                    <a href="https://lipaworld.com" className="underline">
                      www.lipaworld.com
                    </a>
                    .

              </p>

              <div className="author-section2">
                {/* <img
                  src="/img/blog/rev1.png"
                  alt="Author"
                  className="avatar"
                /> */}
                <span className="author-name2">Lipaworld</span>
              </div>
            </div>
          </div>
          <Related />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default VentureCrush;
