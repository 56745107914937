import React from "react";

function FinancialInclusion() {
  return (
    <section className="py-12 px-6">
      <div className="flex flex-col md:flex-row">
        <div
          className="md:w-1/2 mb-4 md:mb-0"
          data-aos="fade-right"
          data-aos-delay="500"
        >
          <img
            src="/img/story/financial-inclusion.jpg"
            alt="African diaspora"
            className="rounded-lg ceoimg"
          />
        </div>

        <div
          className="md:w-1/2 md:pl-8 fidiv"
          data-aos="fade-left"
          data-aos-delay="500"
        >
          <h2 className="text-3xl font-bold mb-4">
            Fostering Financial Inclusion
          </h2>
          <p className="mb-4 fip">
            We are dedicated to fostering financial inclusion for immigrant and
            informal communities in the USA and abroad. Lipaworld offers
            accessible financial services to empower individuals who have been
            excluded from traditional banking systems. Our goal is not only to
            enhance their economic well-being but also to drive social and
            environmental progress.
          </p>
          <h3 className="text-2xl font-bold mb-4">
            Why Financial Inclusion Matters
          </h3>
          <p className="mb-4 fip">
            Financial inclusion is a key driver of poverty reduction and
            economic growth. By providing tools that allow African immigrants to
            build credit, save money, and access financial services, we help
            them contribute more fully to their communities. This strengthens
            local economies, promotes resilience, and builds community
            development.
          </p>
        </div>
      </div>
    </section>
  );
}

export default FinancialInclusion;
