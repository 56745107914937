import React from 'react';

function Top() {
  return (
    <header className=" py-4 px-6">
      <h1 className="text-purple-800 text-xl top2">The Lipaworld Story</h1>
    </header>
  );
}

export default Top;