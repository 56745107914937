import React, { useState } from "react";
import VoucherDetails from "./VoucherDetails/VoucherDetails";

const Header = ({ title, resultCount }) => {
  return (
    <header className="headerv">
      <h1 className="header-titlev">{title}</h1>
      <span className="header-countv">{resultCount} results</span>
    </header>
  );
};

const Card = ({ name, price, onClick }) => {
  return (
    <div className="cardv" onClick={onClick}>
      <img
        src="/img/shop/yellow.png"
        alt="Product Image"
        className="card-imagev"
      />
      <div className="card-contentv">
        <h3 className="card-titlev">{name}</h3>
        <p className="card-pricev">{price}</p>
        <button className="card-buttonv">
          Add to Cart <span className="iconv">🛒</span>
        </button>
      </div>
    </div>
  );
};

const ProductList = ({ onCardClick }) => {
  const products = Array.from({ length: 20 }, (_, i) => ({
    id: i + 1,
    name:
      i === 0 ? "University of the Witwatersrand, Johannesburg" : "MTN Voucher",
    price: "NGN 1000",
  }));

  return (
    <div className="product-listv">
      {products.map((product) => (
        <Card
          key={product.id}
          name={product.name}
          price={product.price}
          onClick={onCardClick}
        />
      ))}
    </div>
  );
};

const VoucherModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-10">
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-full max-w-3xl">
        <VoucherDetails onClose={onClose} />
      </div>
    </div>
  );
};

const AllVouchersWithModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="all-vouchers">
      <Header title="All Vouchers" resultCount={900} />
      <ProductList onCardClick={() => setIsModalOpen(true)} />
      <VoucherModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default AllVouchersWithModal;
