import React from "react";

const SendHolidayGifts = () => {
  return (
    <section className=" py-12 sendsec">
      <div className="container containerm mx-auto px-4">
        <div className="flex flex-col flex-colm md:flex-row items-center">
          <div
            className="md:w-1/2 mb-4 md:mb-0 sendmob mb4mob"
            data-aos="fade-right"
          >
            <img
              src="/img/send/purple.png"
              alt="People with gifts"
              className="rounded-lg purp"
            />
          </div>
          <div
            className="md:w-1/2 md:pl-8 plmob text-center-mobile"
            data-aos="fade-left"
          >
            <h2 className="text-3xl font-bold mb-4 send">
              Send holiday gifts to loved ones back home
            </h2>
            <p className="mb-4 sendp">
              With Lipaworld, you can easily send digital vouchers, top-ups and
              gift cards your family can use to shop or pay for services back
              home this holiday.
            </p>
            <a
              href="https://shop.lipaworld.com/?recipientCountryCode=ZA"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-green-600 text-white px-6 py-2 rounded hover:bg-green-900 transition duration-300 shadow"
            >
              Send a gift today
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendHolidayGifts;
