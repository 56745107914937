import React from "react";

const steps = [
  "Visit Lipaworld to begin your journey",
  "Browse through our categories",
  "Input recipient’s details",
  "Proceed to make a card payment",
  "Recipient receives the digital voucher pins",
];

const HowItWorks = () => {
  return (
    <section className="how-section">
      <div className="headerhow" data-aos="fade-down">
        <h2>How it works</h2>
        <p>Effortless Gifting in Just Five Easy Steps</p>
      </div>
      <div className="contenthow">
        <div className="left-sidehow" data-aos="fade-right">
          <ul>
            {steps.map((step, index) => (
              <li key={index} className="step-itemhow">
                <img
                  src={`./img/howitworks/icon-${index + 1}.svg`}
                  alt=""
                  className="step-iconhow"
                />
                <span>{step}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="right-sidehow" data-aos="fade-left">
          <div className="text-contenthow">
            <img
              src="./img/howitworks/howglobe.png"
              alt="Icon"
              className="globe-iconhow"
            />
            <h3>Visit the Lipaworld shop and begin your journey</h3>
            <p>
              Once Signed up, you can easily access more features such as buying
              and sending vouchers, top-ups and gift cards.
            </p>
          </div>
          <img
            src="./img/howitworks/how.png"
            alt="Mobile App Screen"
            className="mobile-imagehow"
          />
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
