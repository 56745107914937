import { useState } from "react";
import { ChevronDown } from "lucide-react";

const getFlagUrl = (countryCode) => {
  return `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;
};

const CountrySelector = () => {
  const [fromCountry, setFromCountry] = useState("United States");
  const [toCountry, setToCountry] = useState("Nigeria");
  const [isFromOpen, setIsFromOpen] = useState(false);
  const [isToOpen, setIsToOpen] = useState(false);

  const fromCountries = [
    { name: "United States", code: "US" },
    { name: "Canada", code: "CA" },
    { name: "United Kingdom", code: "GB" },
  ];

  const toCountries = [
    { name: "Nigeria", code: "NG" },
    { name: "Kenya", code: "KE" },
    { name: "Zimbabwe", code: "ZW" },
    { name: "Ghana", code: "GH" },
    { name: "South Africa", code: "ZA" },
  ];

  const handleFindVoucher = () => {
    const selectedCountry = toCountries.find((c) => c.name === toCountry);
    const countryCode = selectedCountry?.code;
    window.location.href = `https://shop.lipaworld.com?country=${countryCode}`;
  };

  return (
    <div
      className="max-w-md mx-auto p-3 bg-white rounded-lg shadow-sm cs relative z-10 floating-converter"
      data-aos="fade-left"
    >
      <h2 className="text-base font-medium mb-2 text-gray-800">
        Where do you want to send your support?
      </h2>

      <div className="space-y-2">
        <div>
          <label className="block text-xs text-gray-600">From</label>
          <div className="relative">
            <button
              className="w-full py-2 px-3 border rounded-lg flex items-center justify-between bg-white"
              onClick={() => setIsFromOpen(!isFromOpen)}
            >
              <div className="flex items-center gap-2">
                <img
                  src={getFlagUrl(
                    fromCountries.find((c) => c.name === fromCountry)?.code
                  )}
                  alt={`${fromCountry} flag`}
                  className="w-6 h-6 rounded-full object-cover border border-gray-100"
                />
                <span>{fromCountry}</span>
              </div>
              <ChevronDown className="w-4 h-4 text-gray-500" />
            </button>

            {isFromOpen && (
              <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                {fromCountries.map((country) => (
                  <button
                    key={country.name}
                    className="w-full py-2 px-3 text-left hover:bg-gray-50 flex items-center gap-2"
                    onClick={() => {
                      setFromCountry(country.name);
                      setIsFromOpen(false);
                    }}
                  >
                    <img
                      src={getFlagUrl(country.code)}
                      alt={`${country.name} flag`}
                      className="w-6 h-6 rounded-full object-cover border border-gray-100"
                    />
                    <span>{country.name}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <div>
          <label className="block text-xs text-gray-600">To</label>
          <div className="relative">
            <button
              className="w-full py-2 px-3 border rounded-lg flex items-center justify-between bg-white"
              onClick={() => setIsToOpen(!isToOpen)}
            >
              <div className="flex items-center gap-2">
                <img
                  src={getFlagUrl(
                    toCountries.find((c) => c.name === toCountry)?.code
                  )}
                  alt={`${toCountry} flag`}
                  className="w-6 h-6 rounded-full object-cover border border-gray-100"
                />
                <span>{toCountry}</span>
              </div>
              <ChevronDown className="w-4 h-4 text-gray-500" />
            </button>

            {isToOpen && (
              <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-lg z-10">
                {toCountries.map((country) => (
                  <button
                    key={country.name}
                    className="w-full py-2 px-3 text-left hover:bg-gray-50 flex items-center gap-2"
                    onClick={() => {
                      setToCountry(country.name);
                      setIsToOpen(false);
                    }}
                  >
                    <img
                      src={getFlagUrl(country.code)}
                      alt={`${country.name} flag`}
                      className="w-6 h-6 rounded-full object-cover border border-gray-100"
                    />
                    <span>{country.name}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <button
          className="w-full py-2 px-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors mt-2"
          onClick={handleFindVoucher}
        >
          Find Voucher
        </button>
      </div>
    </div>
  );
};

export default CountrySelector;
