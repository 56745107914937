import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const BackButton = () => (
<a href="/shop">
<button className="back-buttonc">
    <span className="arrow-leftc">←</span> Back
  </button></a>
);

const CartItem = () => {
  const [quantity, setQuantity] = useState(1);

  return (
    <div className="cart-itemc">
      <div className="flex items-center gap-4">
        <div className="w-20 h-20 bg-yellow-400 flex items-center justify-center">
          <div className="text-black font-bold border-2 border-black rounded-full px-3 py-1">
            MTN
          </div>
        </div>
        <div className="cart-item-detailsc">
          <h3 className="item-titlec">MTN Voucher</h3>
          <p className="item-pricec">NGN 1000</p>
        </div>
      </div>
      <div className="cart-item-actionsc">
        <button
          onClick={() => setQuantity((prev) => Math.max(1, prev - 1))}
          className="decrementc"
        >
        <img src="/img/shop/minus-cirlce.png" />
        </button>
        <div className="quantityc">{quantity}</div>
        <button
          onClick={() => setQuantity((prev) => prev + 1)}
          className="incrementc"
        >
        <img src="/img/shop/add-circle.png" />
        </button>
        <button className="removec"><img src="/img/shop/trash.png" /></button>
      </div>
    </div>
  );
};

const Cart = () => {
  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <BackButton />
          <div className="cart-summaryc">
            <h1 className="cart-titlec">Cart</h1>
            <p className="item-countc">1 Item</p>
          </div>
          <div className="cart-itemsc">
            <CartItem />
            <CartItem />
            <CartItem />
            <CartItem />
          </div>
          <div className="total-sectionc">
            <span className="total-textc">Total</span>
            <span className="total-amountc">NGN 1000</span>
          </div>
          <div className="actionsc">
            <button className="action-buttonc continuec">
              Continue to Recipient
            </button>
            <button className="action-buttonc cancelc">Cancel</button>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default Cart;
