import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import Header from "../components/Header";
import GetInTouch from "../components/contact-us/GetInTouch";
import Contact from "../components/contact-us/Contact";
import FAQ from "../components/Faqs";
import GetStarted from "../components/GetStarted";
import Footer from "../components/Footer";

const ContactUs = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: window.location.title,
    });
  }, []);

  return (
    <><Helmet>
      <title>Contact us - Lipaworld - Send more than Money</title>
      <meta
        name="description"
        content="Lipaworld is a platform that allows you to send digital vouchers to your loved ones in Africa." />
      <meta
        name="keywords"
        content="Lipaworld, Lipa, World, Lipa World, Send, vouchers to Africa, Send Vouchers to Kenya, Send Vouchers to Nigeria, Send Vouchers to Ghana, Send Vouchers to Uganda, Send Vouchers to Tanzania, Send Vouchers to Rwanda, Send Vouchers to Ethiopia, Send Vouchers to Somalia, Send Vouchers to South Sudan, Send Vouchers to Burundi, Send Vouchers to Zambia, Send Vouchers to Zimbabwe, Send Vouchers to Malawi, Send Vouchers to Mozambique, Send Vouchers to Botswana, Send Vouchers to Namibia, Send Vouchers to Lesotho, Send Vouchers to Swaziland, Send Vouchers to South Africa, Send Vouchers to Angola, Send Vouchers to Cameroon, Send Vouchers to Chad, Send Vouchers to Congo, Send Vouchers to Gabon, Send Vouchers to Equatorial Guinea, Send Vouchers to Central African Republic, Send Vouchers to Democratic Republic of Congo, Send Vouchers to Benin, Send Vouchers to Burkina Faso, Send Vouchers to Cape Verde, Send Vouchers to Gambia, Send Vouchers to Guinea, Send Vouchers to Guinea-Bissau, Send Vouchers to Ivory Coast, Send Vouchers to Liberia" />
      <meta name="author" content="Lipaworld" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="msapplication-navbutton-color" content="#ffffff" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#ffffff" />
    </Helmet>
    
    <div className="min-h-screen">
        <div className="container mx-auto px-4">
          <Header />
          <main>
            <GetInTouch />
            <Contact />
            <FAQ />
            <GetStarted />
            <Footer />

          </main>
        </div>
      </div></>
  );
};

export default ContactUs;