import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Related = () => {
  const location = useLocation();
  const currentSlug = location.pathname.split('/blog/')[1];

  const articles = [
    {
      title: "Lipaworld partners with CheckUps to unlock healthcare benefits for families of Kenyans back at home",
      author: "Lipaworld",
      date: "September 30, 2024",
      readTime: "10min read",
      description: "The innovative partnership offers secure remittances and inclusive healthcare benefits to families in Kenya, bridging the gap between...",
      slug: "Lipaworld-partners-with-CheckUps",
      image: '/img/blog/checkups.png'
    },
    {
      title: "Lipaworld Accepted into Prestigious VentureCrushFG Pod Program",
      author: "Lipaworld",
      date: "July 25, 2024",
      readTime: "8min read",
      description: "Lipaworld, a leading innovator in digital financial solutions, is thrilled to announce its acceptance into the VentureCrushFG Pod program. This significant milestone marks a pivotal step in...",
      slug: "Lipaworld-Accepted-into-Prestigious-VentureCrushFG-Pod-Program",
      image: '/img/blog/ventureCrush.png'
    },
    {
      title: "5 Thoughtful Gift Ideas for Father's Day",
      author: "Lipaworld",
      date: "June 16, 2024",
      readTime: "12min read",
      description: "Father's Day is here, and finding the perfect gift for the man who has given you so much can be a challenge—especially...",
      slug: "5-Thoughtful-Gift-Ideas-for-Fathers-Day",
      image: '/img/blog/fathersday.jpg'
    },
    {
      title: "Lipaworld Secures First Place in Fintech Category at Startup 302 Pitch Competition",
      author: "Lipaworld",
      date: "May 20, 2024",
      readTime: "15min read",
      description: "Lipaworld, an innovative digital voucher marketplace, clinches first place in the Fintech Category at the prestigious...",
      slug: "lipaworld-secures-first-place-in-fintech-category-at-startup-302-pitch-competition",
      image: '/img/blog/ceo.jpg'
    },
  ];

  const getRandomRelatedArticles = () => {
    const otherArticles = articles.filter(article => article.slug !== currentSlug);
    let randomArticles = [];
    
    // If we have 3 or fewer articles left, return all of them
    if (otherArticles.length <= 3) {
      return otherArticles;
    }
    
    // Get 3 random articles
    const tempArticles = [...otherArticles];
    while (randomArticles.length < 3) {
      const randomIndex = Math.floor(Math.random() * tempArticles.length);
      randomArticles.push(tempArticles[randomIndex]);
      tempArticles.splice(randomIndex, 1);
    }
    
    return randomArticles;
  };

  const relatedArticles = getRandomRelatedArticles();


  return (
    <div className="related-section">
      <h2 className="related-title">Related</h2>
      <div className="article-container">
      {relatedArticles.map((article, index) => (
          <Link to={`/blog/${article.slug}`} key={index} className="article-card">
            <img src={article.image} alt="Article" className="article-image" />
            <div className="article-content">
              <div className="metadata">
                {/* <span>Category</span>
                <img src='/img/testimonials/rev1.png' alt="Dot" className="dot" /> */}
                <span>{article.date}</span>
                <img src='/img/testimonials/rev1.png' alt="Dot" className="dot" />
                <span>{article.readTime}</span>
              </div>
              <h3 className="article-title">{article.title}</h3>
              <p className="article-description">{article.description}</p>
              <div className="author-section">
                {/* <img src='/img/testimonials/rev1.png' alt="Author" className="avatar" /> */}
                <span className="author-name">{article.author}</span>
                <button className="read-more-button">Read more</button>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Related;
