import React from "react";

const Features = () => {
  const features = [
    {
      image: "./img/features/money-forbidden.png",
      title: "Purpose Bound",
      description:
        "Transfer money at great rates, ensuring that more of your hard-earned money reaches your loved ones back home.",
    },
    {
      image: "./img/features/security-card.png",
      title: "Secure Payments",
      description:
        "We use industry-leading encryption and partner with trusted payment providers to ensure every transaction is safe, secure, and reliable.",
    },
    {
      image: "./img/features/flash.png",
      title: "Fast Transfers",
      description:
        "Send vouchers instantly so your support reaches home fast. Ensure your loved ones receive assistance when they need it most.",
    },
  ];

  return (
    <section className="features-section">
      <h2 className="features-title" data-aos="fade-left">
        Manage your money, your way
      </h2>
      <p className="rec" data-aos="fade-left">
        Ensure your funds are used for what matters most
      </p>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card" data-aos="fade-right">
            <div className="feature-icon">
              <img src={feature.image} alt={feature.title} />
            </div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
