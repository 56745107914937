import React from 'react';

const OurStories = () => {
  return (
    <section className="stories-section">
      <div className="stories-container">
        <h2 className="stories-title" data-aos="fade-left">Our Stories & Latest News</h2>
        <p className="stories-description" data-aos="fade-right">
          Stay updated with the latest news and stories from Lipaworld. Discover how we're revolutionizing digital payments and financial solutions across Africa. From innovative products to impactful initiatives, learn about our journey and achievements in driving financial inclusion.
        </p>
      </div>
    </section>
  );
};

export default OurStories;
