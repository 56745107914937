import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Top from "../components/about-us/Top";
import Story from "../components/about-us/Story";
import FinancialInclusion from "../components/about-us/FinancialInclusion";
import Statistics from "../components/about-us/Statistics";
import TailoredServices from "../components/about-us/TailoredServices";
import Experience from "../components/about-us/Experience";
import Header from "../components/Header";
import Testimonials from "../components/Testimonials";
import GetStarted from "../components/GetStarted";
import Footer from "../components/Footer";

const AboutUs = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.title,
    });
  }, []);

  return (
    <div className="min-h-screen ">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <Top />
          <Story />
          <FinancialInclusion />
          <Statistics />
          <TailoredServices />
          <Experience />
          <Testimonials />
          <GetStarted />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
