import React from "react";
import { useNavigate } from "react-router-dom";

const Grow = () => {
  const navigate = useNavigate();

  const handleClick = () => {
  navigate("/contact-us");
  };


  return (
    <div className="grow-section">
      <div className="growcontent">
        <h1 className="growtitle">Grow with the Community</h1>
        <p className="growdescription">
          Lipaworld is a trusted platform. By partnering with us, your business
          will engage with a dedicated community, benefit from ongoing support,
          and join events and promotions to boost your brand.
        </p>
        <button className="growcontact-button" onClick={handleClick}>Contact Us</button>
      </div>
      <div className="growimage-container">
        <img src='/img/partnerpage/grow.png' alt="Community" className="growimage" />
      </div>
    </div>
  );
};

export default Grow;
