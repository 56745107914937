import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import TermsOfUseText from "../components/TermsOfUseText";
import Header from "../components/Header";
import Footer from "../components/Footer";

const TermsOfUse = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.title,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen ">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <TermsOfUseText />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default TermsOfUse;
