import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleClick = () => {
    window.open(
      "https://www.trustpilot.com/review/lipaworld.com",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const reviews = [
    {
      name: "James",
      text: "I used Lipaworld outside of South Africa and also during my visit back home. Lipaworld provided a more convenient way of purchasing airtime while I was back home in South Africa. Overall it's a great service that delivers on its promise!",
      date: "22 May 2024",
    },
    {
      name: "Takudzwa Chiringa",
      text: "Simple, fast and convenient. Excellent product.",
      date: "11 April 2024",
    },
    {
      name: "Christopher Els",
      text: "Such a fast and efficient way to send money and vouchers back home. Thanks!",
      date: "3 July 2024",
    },
    {
      name: "Victor Emmanuel",
      text: "Very straight to the point process. Easy to navigate the website.",
      date: "11 April 2024",
    },
    {
      name: "Buks Ngcolomba",
      text: "Lipaworld digital vouchers offered me a simplified and convenient way of receiving money and transacting. A great solution that has left a lasting impression!",
      date: "20 April 2024",
    },
    {
      name: "Mano Juvane",
      text: "Lipaworld is so easy to use, seamless and practical. My beneficiaries in South Africa were impressed with the quick and easy service. Lipaworld remits more than money!",
      date: "11 April 2024",
    },
    {
      name: "Mitchell Ndlovu",
      text: "I recently used Lipaworld to send grocery vouchers to my family back in Zimbabwe and I couldn't be more pleased with the experience. The process was quick and easy, making it incredibly convenient. Highly recommend this service, 5 stars!",
      date: "3 July 2024",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "0px",
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="bg-white overflow-hidden">
      <section className="testimonials-container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="title-sectiontest text-center mb-12" data-aos="fade-up">
          <h2
            className="titletest text-4xl font-bold text-gray-900 tracking-tight mb-4"
            data-aos="fade-down"
          >
            Loved by our users
          </h2>
          <p className="subtitletest text-lg text-gray-600">
            No more second-guessing or worrying about where your hard-earned
            money goes
          </p>
        </div>

        <div
          className="relative"
          style={{ height: "320px" }}
          data-aos="fade-down"
        >
          <Slider ref={sliderRef} {...settings}>
            {reviews.map((review, index) => (
              <div key={index} className="px-4 transition-all duration-300">
                <div
                  className={`review-card top-20 left-20 right-20 transition-all duration-300 flex flex-col bg-white p-6 rounded-2xl shadow-sm border border-gray-200 ${
                    index === activeSlide
                      ? "scale-100 shadow-lg z-10 opacity-100"
                      : "scale-10 opacity-70"
                  }`}
                  style={{
                    height: index === activeSlide ? "290px" : "220px",
                    transform: `scale(${
                      index === activeSlide ? 1 : 0.8
                    }) translateY(${index === activeSlide ? "0px" : "0"})`,
                  }}
                >
                  <div className="stars mb-3">
                    {[...Array(5)].map((_, i) => (
                      <i key={i} className="fa fa-star text-yellow-400"></i>
                    ))}
                  </div>
                  <p className="review-text text-gray-600 text-sm mb-4 overflow-y-auto flex-grow">
                    {review.text}
                  </p>
                  <div className="reviewer mt-auto flex justify-between items-center text-sm">
                    <span className="font-semibold text-gray-900">
                      {review.name}
                    </span>
                    <span className="text-gray-500">{review.date}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="trustpilot-section mt-12 text-center">
          <div className="trustpilot-info flex items-center justify-center gap-4 mb-6">
            {/* <p className="rating font-bold">Excellent</p> */}
            <p className="score">
              <strong>Excellent</strong> 4.2 out of 5
            </p>
            <div className="trustpilot-logo flex items-center gap-2">
              <img
                className="logo-imagetest w-4 h-4"
                src="/img/testimonials/stargreen.png"
                alt="Trustpilot"
              />
              <span>Trustpilot</span>
            </div>
          </div>
          <button
            className="trustpilot-button px-6 py-2 border border-green-300 rounded-lg text-green-700 font-semibold hover:bg-green-50 transition duration-300"
            onClick={handleClick}
          >
            Review us on Trustpilot
          </button>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
