import React from 'react';
import { useNavigate } from 'react-router-dom';

const Power = () => {
  const navigate = useNavigate();

  const handleClick = () => {
  navigate("/contact-us");
  };


  return (
    <div className="power-section">
      <div className="power-image" data-aos="fade-right">
        <img
          src="/img/partnerpage/hand.png"
          alt="Hands holding a globe"
          className="power-image-content"
        />
      </div>
      <div className="power-content" data-aos="fade-left">
        <h1 className="power-title">The Growing Power of African Immigrants</h1>
        <p className="power-description">
          African immigrants are a fast-growing community with significant spending power. Lipaworld
          connects your business to this influential market across the USA, Europe, and Asia.
        </p>
        <button className="power-button"onClick={handleClick}>Get Started</button>
      </div>
    </div>
  );
};

export default Power;
