import React from "react";

const partners = [
  "/img/partners/telkom.png",
  "/img/partners/makro.png",
  "/img/partners/shoprite.png",
  "/img/partners/vodacom.png",
  "/img/partners/cellc.png",
  "/img/partners/mtn.png",
  "/img/partners/bp.png",
  "/img/partners/uber.png",
  "/img/partners/picknpay.png",
  "/img/partners/netcare.png",
];

const Partners = () => {
  return (
    <section className="partners-section">
      <header className="partners-header" data-aos="fade-down">
        <h2 className="text-3xl font-bold mb-4 send">
          Access vouchers, top-ups and gift cards from top brands and services
        </h2>
        <p className="sendp">
          Integrate Lipaworld into your routine and enjoy the ease of sending
          and receiving vouchers from top companies.
        </p>
      </header>
      <div className="partners-logos" data-aos="fade-up">
        {partners.map((partner, index) => (
          <div key={index} className="partner-logo">
            <img src={partner} alt={`Partner ${index}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Partners;
