import React, { useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga4';
import Header from "../components/Header";
import NavShop from "../components/shop/NavShop";
// import AllVouchers from "../components/shop/AllVouchers";
import Footer from "../components/Footer";
import AllVouchersWithModal from "../components/shop/AllVouchers";

const Shop = () => {
    useEffect(() => {
        ReactGA.send({
          hitType: 'pageview',
          page: window.location.pathname + window.location.search,
          title: window.location.title,
        });
      }, []);

    return (
        <div className="min-h-screen ">
                  <div className="container mx-auto px-4">
                    <Header />
                    <main>
                        <NavShop />
                        <AllVouchersWithModal />
                        <Footer />

                    </main>
                  </div>
        </div>
    )
}

export default Shop;