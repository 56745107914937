import React from "react";

const GetStarted = () => {
  const handleClick = () => {
    window.location.href =
      "https://shop.lipaworld.com/?recipientCountryCode=ZA";
  };

  return (
    <section className="getsec text-white py-12">
      <div className="container mx-auto px-4 banner-text-left">
        <div
          className="flex flex-col md:flex-row items-center"
          data-aos="fade-in"
        >
          <div className="md:w-1/2 mb-4 md:mb-0" data-aos="fade-right">
            <h2 className="text-3xl font-bold mb-4 getheader">
              Get started with Lipaworld today!
            </h2>
            <p className="mb-4 getp">
              Create your account, get verified and help support your loved ones
              today!
            </p>
            <button
              className=" text-green-600 px-6 py-2 rounded transition duration-300 getbutton"
              onClick={handleClick}
            >
              Shop Now
            </button>
          </div>
          <div className="md:w-1/2 md:pl-8" data-aos="fade-left">
            <img
              src="/img/getstarted/get.png"
              alt="Man using phone"
              className="getimg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
