import { useState } from 'react';

export const useSubscription = ({ addNewsSubscriber }) => {
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  const subscribe = async ({ name, email }) => {
    try {
      const result = await addNewsSubscriber({ name, email });
      if (result === 'success') {
        setMessage('Subscription request successfully submitted');
        setMessageColor('green');
      } else {
        setMessage('Something went wrong. Please try again later.');
        setMessageColor('red');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      setMessageColor('red');
    }
  };

  return { subscribe, message, messageColor };
};

export default useSubscription
