import React from "react";
import { useNavigate } from "react-router-dom";

const PartnerHero = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us");
  };

  return (
    <div className="flex flex-col lg:flex-row gap-8 lg:gap-20 px-4 lg:px-16 py-8 lg:py-20 bg-[#fefefe] -mb-8 lg:-mb-36 overflow-hidden">
      <div className="w-full lg:max-w-[551px]" data-aos="fade-down">
        <div className="inline-block px-4 py-2.5 bg-white rounded-full shadow-lg text-[#602182] font-semibold text-sm">
          Partner With Us
        </div>

        <section className="py-6 lg:py-12 px-0 lg:px-3">
          <h2
            className="text-3xl lg:text-4xl font-bold mb-6 aos-init aos-animate"
            data-aos="fade-left"
          >
            Join the Lipaworld marketplace and expand your reach!
          </h2>

          <p className="text-base lg:text-lg text-[#55667a] leading-7 mb-8 lg:mb-12">
            Lipaworld is the premier online marketplace connecting businesses to
            the vibrant community of African immigrants across the globe. By
            partnering with us, you can tap into a rapidly growing market,
            boosting your sales and brand recognition like never before.
          </p>

          <div className="flex gap-3">
            <button
              className="px-4 py-2.5 bg-[#00ae5a] text-white rounded-lg border border-[#00bf63] font-semibold text-sm hover:bg-[#009950] transition-colors"
              onClick={handleClick}
            >
              Contact Us
            </button>
            {/* <button className="px-4 py-2.5 bg-white text-[#010e1f] rounded-lg border border-[#e7e7e7] font-semibold text-sm">
            Learn more
          </button> */}
          </div>
        </section>
      </div>

      <div className="w-full lg:w-[621px] h-[300px] lg:h-auto relative">
        <img
          src="/img/partners/globe.jpg"
          alt="Join the Lipaworld marketplace and expand your reach"
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
    </div>
  );
};

export default PartnerHero;
