import React, { useEffect } from "react";
// import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import { initAOS } from "./utils/aos-init";
import LandingPage from "./pages/LandingPage";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Partners from "./pages/Partners";
import Blog from "./pages/Blog";
import Shop from "./pages/Shop";
import Cart from "./pages/cart/Cart";
import LipawordCheckups from "./pages/blog-pages/LipaworldCheckups";
import VentureCrush from "./pages/blog-pages/VentureCrush";
import Startup302 from "./pages/blog-pages/Startup302";
import Fathersday from "./pages/blog-pages/FathersDay";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

const App = () => {
  // ReactGA.initialize(process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID);

  useEffect(() => {
    initAOS();
  }, []);

  if (typeof window !== "undefined") {
    const siteId = 3514117;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/cart" element={<Cart />} />
        <Route
          path="/blog/Lipaworld-partners-with-CheckUps"
          element={<LipawordCheckups />}
        />
        <Route
          path="/blog/Lipaworld-Accepted-into-Prestigious-VentureCrushFG-Pod-Program"
          element={<VentureCrush />}
        />
        <Route
          path="/blog/5-Thoughtful-Gift-Ideas-for-Fathers-Day"
          element={<Fathersday />}
        />
        <Route
          path="/blog/lipaworld-secures-first-place-in-fintech-category-at-startup-302-pitch-competition"
          element={<Startup302 />}
        />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;
