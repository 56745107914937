import React, { memo, useEffect, useState } from "react";

const useWindowSize = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 500);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return isMobile;
};

const RecognitionLogos = memo(({ imageUrl, description }) => {
  const isMobile = useWindowSize();

  return (
    <img
      src={`https://da0das4tnanmm.cloudfront.net/recognition/${imageUrl}`}
      alt={description}
      width={isMobile ? 150 : 200}
      height={isMobile ? 45 : 60.55}
      // className="w-auto h-auto"
    />
  );
});

RecognitionLogos.displayName = "RecognitionLogos";

export default RecognitionLogos;
