import AOS from 'aos';
import 'aos/dist/aos.css';

export const initAOS = () => {
  if (typeof window !== 'undefined') {
    AOS.init({
      duration: 1500,    
      easing: 'ease-in-out',  
      once: true,    
    });
  }
};
