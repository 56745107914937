import React from "react";

function TailoredServices() {
  return (
    <section className="py-12 px-6 text-white tsec">
      <div className="tailoredheader" data-aos="fade-down" data-aos-delay="300">
        <h2 className="text-3xl font-bold mb-4 tailoredh2">
          Tailored services for the immigrant diaspora
        </h2>
        <p className="mb-6 tp">
          Immigrants face unique challenges when navigating financial systems in
          new countries. Lipaworld offers services tailored specifically for the
          immigrant diaspora, making it easier for them to integrate into new
          economies, contributing to social cohesion and inclusivity.
        </p>
      </div>

      <div
        className="w-full h-64 rounded-lg world"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <img src="/img/story/World.png" />
      </div>
    </section>
  );
}

export default TailoredServices;
