import React from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import Related from "../../components/blog/Related";
import Footer from "../../components/Footer";

const FosteringInclusion = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/blog");
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <div className="foster-container">
            <div className="button-main2" onClick={handleBack}>
              <img
                src="/img/blog/pages/arrow-left.png"
                alt="Back"
                className="arrow-left"
              />
              <span className="back-text">Back</span>
            </div>

            <div className="image-section2">
              <img
                src="/img/blog/checkups.png"
                alt="Lipaworld and Checkups med"
                className="community-image"
              />
            </div>

            <div className="content-section2">
              <div className="metadata2">
                <span>Press Release</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>10min read</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>September 30, 2024</span>
              </div>

              <h1 className="title2">Lipaworld partners with CheckUps to unlock healthcare benefits for families of Kenyans back at home</h1>

              <p className="description2">
              <br />
        <br />
        <em>
          The innovative partnership offers secure remittances and inclusive healthcare benefits to families in Kenya,
          bridging the gap between financial support and healthcare access.
        </em>
        <br />
        <br />
        Lipaworld, a leading voucher remittance platform, and CheckUps, a pioneering health benefits management company,
        are thrilled to announce their strategic partnership and the launch of CheckUps COVA in the United States.
        Lipaworld has partnered with CheckUps to offer a premier secure gateway to convert Lipaworld vouchers into
        Health benefits.
        <br />
        <br />
        <h3>About CheckUps</h3>
        Checkups COVA is designed to unlock $4 of health benefits for every $1 paid, ensuring affordable and accessible
        healthcare for families in Kenya.
        <br />
        <br />
        <h3>Key features include:</h3>
        <ul>
          <li>
            <strong>No Exclusions:</strong> Inclusive of all ages and pre-existing conditions.
          </li>
          <li>
            <strong>Comprehensive Coverage:</strong> Benefits allocated to consultations, labs, diagnostics, and
            medications.
          </li>
          <li>
            <strong>On-Demand Care:</strong> Services accessed via WhatsApp for convenience and efficiency.
          </li>
          <li>
            <strong>Peace of Mind:</strong> Guarantees that remittances are used for their intended purpose, providing
            essential healthcare support.
          </li>
        </ul>
        <br />
        <br />
        By eliminating the overheads associated with traditional healthcare systems, CheckUps ensures that employees and
        their families receive necessary medical care at reduced costs.
        <br />
        <br />
        <h3>How the two innovative startups will work together</h3>
        Lipaworld will facilitate the secure transfer of remittances through digital vouchers, while Checkups provides
        comprehensive healthcare services. Together, they bridge the gap between financial support and healthcare
        access, ensuring that families in Kenya receive the care they need without the associated financial strain.
        Lipaworld's robust remittance platform complements CheckUp's innovative health benefits model, creating a
        seamless and impactful solution for Kenyans globally. CheckUps COVA offers outpatient benefits only. When sick,
        members simply text or call +254111 050 290 to request services that come to the home.
        <br />
        <br />
        <em>
          "This partnership with CheckUps is a strategic milestone for Lipaworld, enhancing our mission to support
          immigrant communities. By combining secure remittances with essential healthcare benefits, we are providing a
          holistic solution that truly makes a difference,"
        </em>{' '}
        said Jonathan, CEO of Lipaworld.
        <br />
        <br />
        <em>
          We are excited to collaborate with Lipaworld to launch CheckUps. This product not only addresses critical
          healthcare needs but also ensures that the financial support provided by immigrants reaches its intended
          purpose effectively,"
        </em>{' '}
        stated Moka Lantum, CEO of CheckUps.{' '}
        <em>
          "This is groundbreaking for Kenyan families in the USA. It's a blessing to know your hard-earned dollar gets
          to your family back home to cater for common illnesses."
        </em>
        <br />
        <br />
        <h3>Availability and Pricing:</h3>
        Checkups COVA will be available starting October 1, 2024. Interested customers can sign up and purchase the
        product via the Lipaworld platform. Pricing details and additional information are available on the respective
        company websites.
        <br />
        <br />
        <h3>About Lipaworld:</h3>
        Lipaworld is a leading remittance platform dedicated to providing secure and efficient remittance products and
        services for immigrant communities. By leveraging innovative technology, Lipaworld ensures that remittances
        reach their intended purpose, supporting families and communities worldwide.
        <br />
        <br />
        <h3 className="font-bold  text-black-600 ">About CheckUps:</h3>
        CheckUps is a health benefits management platform focused on delivering affordable and accessible healthcare
        solutions. By partnering with financial institutions and leveraging technology, CheckUps offers inclusive
        healthcare benefits that cater to diverse needs, ensuring no one is left behind.
        <br />
        <br />
        <h3>Contact Information</h3>
        For media inquiries, please contact:
        <br />
        Marketing at Lipaworld
        <br />
        Email: marketing@lipaworld.com
        <br />
        Phone: +1 322 256 4374
        <br />
        <br />
        To learn more about CheckUps and how it can benefit your family, visit{' '} <a href="https://lipaworld.com" className="underline"> www.lipaworld.com </a> or <a href="https://checkupsmed.com" className="underline"> www.checkupsmed.com </a> today. Ensure your loved ones receive the care they deserve with the support they need.

              </p>

              <div className="author-section2">
                {/* <img
                  src="/img/blog/rev1.png"
                  alt="Author"
                  className="avatar"
                /> */}
                <span className="author-name2">Lipaworld</span>
              </div>
            </div>
          </div>
          <Related />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default FosteringInclusion;
