import React from 'react';

const WhyPartnerWithLipaworld = () => {
  return (
    <section className="partners-section">
      <h1 className="partners-header" data-aos="fade-left">Why partner with Lipaworld?</h1>
      <div className="partners-grid" data-aos="fade-right">
        <div className="partner-card">
          <div className="partner-icon">
            <img src="/img/partnerpage/money-forbidden.png" alt="Massive Market Opportunity" />
          </div>
          <h2 className="partner-title">Massive Market Opportunity</h2>
          <p className="partner-description">
            Reach millions of African immigrants worldwide, eager for products and services that remind them of home.
          </p>
        </div>

        <div className="partner-card">
          <div className="partner-icon">
            <img src="/img/partnerpage/security-card.png" alt="High Engagement" />
          </div>
          <h2 className="partner-title">High Engagement</h2>
          <p className="partner-description">
            Our user-friendly platform offers a seamless shopping experience, keeping customers coming back.
          </p>
        </div>

        <div className="partner-card">
          <div className="partner-icon">
            <img src="/img/partnerpage/flash.png" alt="Targeted Marketing" />
          </div>
          <h2 className="partner-title">Targeted Marketing</h2>
          <p className="partner-description">
            Benefit from strategic, tailored marketing that connects your business to the right audience.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyPartnerWithLipaworld;
