import React from 'react';
import { useNavigate } from 'react-router-dom';

const ROI = () => {
  const navigate = useNavigate();

  const handleClick = () => {
  navigate("/contact-us");
  };


  return (
    <section className="roi-section">
      <div className="roi-container">
        <div className="roi-image-wrapper" data-aos="fade-up">
          <img src="/img/partnerpage/ROI.png" alt="Presentation" className="roi-image" />
          <div className="roi-card">
            <div className="roi-card-content">
              <p className="roi-annual">Annual ROI</p>
              <h1 className="roi-percent">802.10%</h1>
              <img src="/img/partnerpage/graph.png" alt="Graph" className="roi-graph" />
            </div>
          </div>
        </div>
        <div className="roi-text-content" data-aos="fade-down">
          <h1 className="roi-heading">Maximize Your Return on Investment</h1>
          <p className="roi-description">
            Starting with Lipaworld is simple and affordable. Gain access to a new customer base
            with minimal costs. Our competitive fees let you keep more profits while increasing your sales.
          </p>
          <button className="roi-button" onClick={handleClick}>Join Us</button>
        </div>
      </div>
    </section>
  );
}

export default ROI;
