import React from "react";

function Story() {
  return (
    <section className="py-12 px-6">
      <div className="storyheader">
        <h2
          className="text-4xl font-bold mb-6 storyh2"
          data-aos="fade-right"
          data-aos-delay="300"
        >
          Empowering the African diaspora
        </h2>
        <p
          className="text-lg mb-6 storyp"
          data-aos="fade-left"
          data-aos-delay="300"
        >
          At Lipaworld, our mission is personal. As immigrants living in the US
          and the UK, we faced the difficulties of sending money to family in
          South Africa, DR Congo, and Zimbabwe. The available options didn't
          meet the needs of informal and undocumented recipients. That's why we
          created Lipaworld—to bridge that gap and provide a solution for the
          African diaspora.
        </p>
      </div>
      <img
        src="/img/story/ceo.jpg"
        alt="Lipaworld presentation"
        className="w-full rounded-lg ceoimg"
      />
    </section>
  );
}

export default Story;
