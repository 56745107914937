import React, { useState } from 'react';
import { ChevronDown, ChevronUp, X, Minus, Plus } from 'lucide-react';

const VoucherDetails = ({ onClose }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(true);
  const [isRedemptionExpanded, setIsRedemptionExpanded] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState('NGN 100');
  const [quantity, setQuantity] = useState(1);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center" onClick={handleOverlayClick}>
      <div className="bg-white rounded-2xl w-full max-w-3xl mx-4">
        <div className="p-6">
          <div className="flex justify-end">
            <button 
              className="p-1 hover:bg-gray-100 rounded-full"
              onClick={onClose}
            >
              <X className="w-6 h-6 text-gray-500" />
            </button>
          </div>
          
          {/* Rest of the component remains the same */}
          <div className="grid md:grid-cols-2 gap-6">
            {/* Left Column */}
            <div>
              <div className="bg-yellow-400 rounded-lg aspect-[16/9] mb-4">
                <img 
                  src="/img/shop/yellow.png"
                  alt="MTN Logo"
                  className="w-full h-full object-contain p-8"
                />
              </div>
              
              <h2 className="text-xl font-semibold">MTN Voucher</h2>
              <p className="text-purple-700 font-semibold mt-1">NGN 1000</p>
              
              <div className="space-y-3 mt-4">
                <button 
                  onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                  className="w-full flex justify-between items-center p-4 rounded-2xl border"
                >
                  <span className="font-semibold">Description</span>
                  {isDescriptionExpanded ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
                </button>
                
                {isDescriptionExpanded && (
                  <div className="p-4 rounded-2xl bg-gray-50">
                    <p className="text-gray-700">
                      MTN is Africa's largest mobile network operator, sharing the benefits 
                      of a modern connected life with 272m customers in 19 markets across Africa
                    </p>
                  </div>
                )}
                
                <button 
                  onClick={() => setIsRedemptionExpanded(!isRedemptionExpanded)}
                  className="w-full flex justify-between items-center p-4 rounded-2xl border"
                >
                  <span className="font-semibold">Redemption Details</span>
                  <ChevronDown className="w-5 h-5" />
                </button>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-4">
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="font-semibold">Select Amount</h3>
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                </div>

                <div>
                  <p className="text-gray-600 font-semibold text-sm mb-3">Suggested Amount</p>
                  <div className="grid grid-cols-4 gap-2">
                    {['NGN 100', 'NGN 500', 'NGN 1000', 'NGN 2000'].map((amount) => (
                      <button
                        key={amount}
                        onClick={() => setSelectedAmount(amount)}
                        className={`py-2 px-3 rounded-lg border text-sm font-semibold ${
                          selectedAmount === amount
                            ? 'bg-green-600 text-white border-green-600'
                            : 'border-gray-200 text-gray-700'
                        }`}
                      >
                        {amount}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="mt-4">
                  <p className="text-gray-600 font-semibold text-sm mb-3">Custom Amount</p>
                  <div className="flex items-center bg-gray-50 rounded-lg border p-2">
                    <div className="flex items-center gap-2 px-3 border-r">
                      <img src="/img/shop/ng.png" alt="NGN flag" className="w-5 h-5" />
                      <span className="text-sm">NGN</span>
                    </div>
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      className="bg-transparent px-3 py-1 flex-1 outline-none"
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="font-semibold">Select Quantity</h3>
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                </div>
                <div className="flex items-center gap-2">
                  <button 
                    onClick={() => quantity > 1 && setQuantity(quantity - 1)}
                    className="p-2 border rounded-lg"
                  >
                    <Minus className="w-5 h-5" />
                  </button>
                  <input
                    type="text"
                    value={quantity}
                    readOnly
                    className="w-full p-2 text-center bg-gray-50 rounded-lg"
                  />
                  <button 
                    onClick={() => setQuantity(quantity + 1)}
                    className="p-2 border rounded-lg"
                  >
                    <Plus className="w-5 h-5" />
                  </button>
                </div>
              </div>

              <div className="pt-4">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-gray-600">Total</span>
                  <span className="text-purple-700 font-semibold">NGN 1000</span>
                </div>
                <button className="w-full py-3 bg-green-600 text-white rounded-lg font-semibold mb-2">
                  Buy Now
                </button>
                <button className="w-full py-3 text-green-600 border border-green-600 rounded-lg font-semibold">
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherDetails;