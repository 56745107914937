import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Related from "../../components/blog/Related";
import Footer from "../../components/Footer";

const Fathersday = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/blog");
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <div className="foster-container">
            <div className="button-main2" onClick={handleBack}>
              <img
                src="/img/blog/pages/arrow-left.png"
                alt="Back"
                className="arrow-left"
              />
              <span className="back-text">Back</span>
            </div>

            <div className="image-section2">
              <img
                src="/img/blog/fathersday.jpg"
                alt="Father's day"
                className="community-image"
              />
            </div>

            <div className="content-section2">
              <div className="metadata2">
                <span>Stories</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>10min read</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>June 16, 2024</span>
              </div>

              <h1 className="title2">
                5 Thoughtful Gift Ideas for Father's Day
              </h1>

              <p className="description2">
                Father’s Day is here, and finding the perfect gift for the man
                who has given you so much can be a challenge—especially if
                you’re miles away. This year, why not go beyond the usual and
                give your father something truly meaningful? With Lipaworld, you
                can send support that makes a real difference. Here are five
                thoughtful gift ideas that your dad will love.
              </p>

              <div>
                <h3 className="h3-md">1. Healthcare Vouchers</h3>
                <p className="p-lg">
                  Ensuring your dad has access to quality healthcare is one of
                  the most thoughtful gifts you can give. Lipaworld&apos;s
                  healthcare vouchers cover GP consultations, nurse visits, lab
                  tests, and specialist consultations. Give your dad the gift of
                  health and peace of mind.
                </p>
              </div>

              <div>
                <h3 className="h3-md">2. Utility Bill Payments</h3>
                <p className="p-lg">
                  Help your dad manage his household expenses by paying for his
                  utility bills. Lipaworld makes it easy to send vouchers that
                  can be used for water and electricity bills, ensuring that his
                  home runs smoothly.
                </p>
              </div>

              <div>
                <h3 className="h3-md">3. Groceries and Food Supplies</h3>
                <p className="p-lg">
                  Send a grocery voucher that your dad can redeem at local
                  wholesalers and retail stores. This practical gift will help
                  him stock up on essentials and enjoy his favorite foods
                  without worrying about the cost.
                </p>
              </div>

              <div>
                <h3 className="h3-md">4. Educational Support</h3>
                <p className="p-lg">
                  If your dad is passionate about education or you have younger
                  children to support, consider sending an educational voucher.
                  These vouchers can be used to pay for school fees and
                  supplies.
                </p>
              </div>

              <div>
                <h3 className="h3-md">5. Communication Vouchers</h3>
                <p className="p-lg">
                  Stay connected with your dad by sending a prepaid airtime and
                  internet voucher. This gift ensures he can always reach out
                  and stay in touch, bridging the distance between you.
                </p>
              </div>

              <div>
                <h3 className="h3-md">Conclusion</h3>
                <p className="p-lg">
                  This Father&apos;s Day, make your gift count with Lipaworld.
                  Whether it&apos;s healthcare, groceries, or utility bills, our
                  vouchers offer a practical and heartfelt way to show your
                  appreciation. Visit Lipaworld today to choose the perfect gift
                  for your dad.
                </p>
              </div>
              <div>
                <p className="p-lg fd">
                  Ready to make a difference this Father&apos;s Day? Sign up on{" "}
                  <a href="https://www.lipaworld.com" className="underline">
                    Lipaworld
                  </a>{" "}
                  and send a gift that truly matters.
                </p>
              </div>

              <div className="author-section2">
                {/* <img src="/img/blog/rev1.png" alt="Author" className="avatar" /> */}
                <span className="author-name2">Lipaworld</span>
              </div>
            </div>
          </div>
          <Related />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default Fathersday;
