import React, { useState, useRef, useEffect } from "react";
import ReactGA from "react-ga4";
import Header from "../components/Header";
import PartnerHero from "../components/partners/PartnerHero";
import Recognition from "../components/recognition";
import WhyPartnerWithLipaworld from "../components/partners/Why";
import ROI from "../components/partners/ROI";
import Grow from "../components/partners/Grow";
import Power from "../components/partners/Power";
import Explore from "../components/partners/Explore";
import FAQ from "../components/Faqs";
import ReadyToPartner from "../components/partners/Ready";
import Footer from "../components/Footer";

const Partners = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.title,
    });
  }, []);

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <PartnerHero />
          <Recognition topMargin={100} />
          <WhyPartnerWithLipaworld />
          <ROI />
          <Grow />
          <Power />
          <Explore />
          <FAQ />
          <ReadyToPartner />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default Partners;
