import { db } from '../services/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

const suspiciousCharacters = ['<', '>', '&'];

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function isTextTooLong(text) {
  return text.length > 555;
}

function containsSuspiciousCharacter(string) {
  return suspiciousCharacters.some((character) => string.includes(character));
}

export const addNewsSubscriber = async (data) => {
  try {
    if (containsSuspiciousCharacter(data.name) || containsSuspiciousCharacter(data.email)) {
      throw new Error('Suspicious characters found.');
    }
    if (!validateEmail(data.email)) {
      throw new Error('Invalid email address');
    }
    if (isTextTooLong(data.name) || isTextTooLong(data.email)) {
      throw new Error('Text is too long');
    }

    const docRef = await addDoc(collection(db, 'newsSubscribers'), data);
    if (docRef.id) {
      return 'success';
    }
  } catch (e) {
    return 'error';
  }
};

export const sendEnquiry = async (data) => {
  try {
    if (
      containsSuspiciousCharacter(data.name) ||
      containsSuspiciousCharacter(data.subject) ||
      containsSuspiciousCharacter(data.phone) ||
      containsSuspiciousCharacter(data.message) ||
      containsSuspiciousCharacter(data.companyName) ||
      containsSuspiciousCharacter(data.email)
    ) {
      throw new Error('Suspicious characters found.');
    }
    if (!validateEmail(data.email)) {
      throw new Error('Invalid email address');
    }
    if (
      isTextTooLong(data.name) ||
      isTextTooLong(data.subject) ||
      isTextTooLong(data.phone) ||
      isTextTooLong(data.message) ||
      isTextTooLong(data.companyName) ||
      isTextTooLong(data.email)
    ) {
      throw new Error('Text is too long');
    }

    const docRef = await addDoc(collection(db, 'enquiries'), data);

    if (docRef.id) {
      return 'success';
    }
  } catch (e) {
    return 'error';
  }
};

export const sendPartnerEnquiry = async (data) => {
  try {
    if (
      containsSuspiciousCharacter(data.name) ||
      containsSuspiciousCharacter(data.businessDetails) ||
      containsSuspiciousCharacter(data.phone) ||
      containsSuspiciousCharacter(data.website) ||
      containsSuspiciousCharacter(data.companyName) ||
      containsSuspiciousCharacter(data.email) ||
      containsSuspiciousCharacter(data.productCategories)
    ) {
      throw new Error('Suspicious characters found.');
    }
    if (!validateEmail(data.email)) {
      throw new Error('Invalid email address');
    }
    if (
      isTextTooLong(data.name) ||
      isTextTooLong(data.productCategories) ||
      isTextTooLong(data.phone) ||
      isTextTooLong(data.website) ||
      isTextTooLong(data.companyName) ||
      isTextTooLong(data.email)
    ) {
      throw new Error('Text is too long');
    }

    const docRef = await addDoc(collection(db, 'partner-enquiries'), data);

    if (docRef.id) {
      return 'success';
    }
  } catch (e) {
    return 'error';
  }
};
