const faqData = [
    {
      category: 'General Information',
      questions: [
        {
          summary: 'What is Lipaworld?',
          details:
            'Lipaworld is an innovative digital voucher marketplace that revolutionizes the way you remit back home to Africa. With digital vouchers, you can effortlessly support your loved ones by empowering them with instant access to essential goods and services, all while enjoying low fees and total transparency. Digital vouchers are sent via SMS text, Email or via WhatsApp and redeemed by the receiver at the point of sale or online. Say goodbye to traditional cash transfers and embrace a faster, more efficient method with Lipaworld!',
        },
        {
          summary: 'How does Lipaworld work?',
          details:
            'Users can purchase vouchers and top-up their accounts on our platform, which can then be redeemed or loaded to a recipient in Africa for various goods and services.',
        },
        {
          summary: 'Does the recipient need the Lipaworld web app to receive a voucher PIN?',
          details:
            'The recipient does not need to have the web app to receive the voucher PIN. They will receive it directly via WhatsApp.',
        },
      ],
    },
    {
      category: 'Account and Registration',
      questions: [
        {
          summary: 'How do I create an account on Lipaworld?',
          details:
            'To create an account, click on the "Sign Up" button on our homepage, then register on the Lipaworld shop page and follow the prompts to enter your personal information and verify your email address.',
        },
        {
          summary: 'What should I do if I forget my password?',
          details:
            'Click on the "Forgot Password" on the login page, confirm your email address, and you will receive an email with a prompt to reset your password.',
        },
        {
          summary: 'How do I close my Lipaworld account?',
          details: 'Please contact our customer support team for assistance with closing your account.',
        },
      ],
    },
  
    {
      category: 'Sending Vouchers',
      questions: [
        {
          summary: 'How do I purchase a voucher? ',
          details: `To purchase a voucher:
    Sign in to your account.
    Browse through the different voucher categories to find the one you'd like to send.
    Create recipient details.
    Choose the quantity of vouchers you want to send.
    Select a recipient for the selected voucher.
    Add the voucher to your cart by clicking "Add to Cart".
    Select your cart to make sure everything is correct.
    Complete your purchase by clicking "Proceed to Checkout".
    Process payment powered by PayPal.
    Complete your KYC process and be verified.
    `,
        },
        {
          summary: 'What types of vouchers are available? ',
          details:
            'We offer a variety of vouchers, including those for groceries, airtime, utilities, and more. Explore our website to discover the full selection.',
        },
        {
          summary: `What is Lipaworld's policy on voucher refunds?`,
          details:
            'We don’t offer refunds on vouchers, unfortunately; however, this may be dealt with on a case-by-case basis depending on the nature of the transaction. Each redemption partner has their own terms and conditions regarding returns. Check their T&Cs (usually available on their website).',
        },
        {
          summary: 'As the sender, can I track if a voucher has been redeemed?',
          details: 'Currently no, but we aim to add that  feature in the upcoming months. ',
        },
      ],
    },
  
    {
      category: 'Payment and Fees',
      questions: [
        {
          summary: 'What payment methods are accepted?',
          details:
            'We offer secure and convenient payment options for your voucher purchases through Visa, Mastercard, and PayPal and Venmo.',
        },
        {
          summary: 'Are there any fees for sending vouchers? ',
          details:
            'Yes, there is a transaction & processing fee for each transaction. The fee will be displayed on the cart page before you proceed to checkout.',
        },
      ],
    },
    {
      category: 'Receiving and Redeeming Vouchers',
      questions: [
        {
          summary: 'How will my recipient receive the voucher?',
          details: `Once you've purchased a voucher, you can easily share it with your recipient. Simply click the share button (WhatsApp, Email, SMS - if using a mobile browser) on the post pay page and click your preferred option to send the voucher PIN directly to your recipient.
    Top-ups will be automatically credited to their account.`,
        },
        {
          summary: 'How do I redeem my voucher? ',
          details: 'Detailed instructions on redeeming each voucher can also be found on our marketplace.',
        },
        {
          summary: 'What happens if I lose my voucher?',
          details:
            'Voucher PINs are unique, and a lost PIN is like lost cash, so unfortunately, there is no way we can get it back. Always keep your PIN safe.',
        },
        {
          summary: `My voucher is not working. What should I do? `,
          details: 'Never panic. Contact us via our support channels for assistance.',
        },
        {
          summary: 'How long is my voucher valid for? ',
          details:
            'Vouchers can be used at all the supporting outlets listed on our marketplace. Check our ever-expanding partner list on our website: www.lipaworld.com.',
        },
      ],
    },
    {
      category: 'Security and Privacy',
      questions: [
        {
          summary: 'Is my personal information safe with Lipaworld? ',
          details:
            'Yes, we use advanced encryption and security measures to protect your personal information and ensure your transactions are secure.',
        },
        {
          summary: 'What should I do if I suspect fraud or unauthorized activity on my account? ',
          details:
            'Immediately contact our customer support team to report any suspicious activity. We will assist you in securing your account.',
        },
        {
          summary: 'What security measures are in place to protect my vouchers?',
          details:
            'The onus is on you to make sure your voucher pin is sent to the correct recipient. We ask you to ensure that you send any pins to the correct recipients to avoid any losses. ',
        },
      ],
    },
  
    {
      category: 'Support and Assistance',
      questions: [
        {
          summary: 'How can I contact Lipaworld customer support? ',
          details:
            'You can contact our customer support team via email at support@lipaworld.com or on the live chat functionality on our website ',
        },
        {
          summary: 'What should I do if my recipient did not receive the voucher? ',
          details:
            'Please contact our customer support team with the transaction details, and we will investigate and resolve the issue promptly.',
        },
      ],
    },
  
    {
      category: 'Technical Issues',
      questions: [
        {
          summary: `I'm having trouble logging in to my account. What should I do?`,
          details: 'Please contact our support team via email at support@lipaworld.com for assistance.',
        },
        {
          summary: 'My page keeps on loading. What should I do? ',
          details: `Try refreshing the page or clearing your browser's cache. If the problem persists, contact our customer support team for assistance.`,
        },
      ],
    },
    {
      category: 'Additional Information',
      questions: [
        {
          summary: 'Can I cancel or modify a voucher after it has been sent? ',
          details:
            'Once a voucher has been purchased, it cannot be canceled or modified. Please ensure all details are correct before completing the transaction.',
        },
      ],
    },
  ];
  
  export default faqData;
  
  