import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronRight, ChevronLeft, Users } from "lucide-react";

const Experience = () => {
  const [showAll, setShowAll] = useState(false);
  const [sliderView, setSliderView] = useState(false);

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-full p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 focus:outline-none"
      >
        <ChevronRight className="w-6 h-6 text-gray-600" />
      </button>
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-full p-2 rounded-full bg-white shadow-lg hover:bg-gray-50 focus:outline-none"
      >
        <ChevronLeft className="w-6 h-6 text-gray-600" />
      </button>
    );
  }

  return (
    <section
      className="py-16 px-6 max-w-7xl mx-auto text-center"
      data-aos="fade-right"
      data-aos-delay="100"
    >
      <h2 className="text-4xl font-bold mb-6 text-gray-900 tracking-tight">
        Our experience and expertise
      </h2>
      <p className="text-lg text-gray-600">
        Backed by over two decades of experience in tech, app-based marketing,
        SaaS, and financial services, our team brings deep expertise to
        Lipaworld. We've worked with top tech companies in Africa and globally,
        giving us the skills to scale businesses and drive growth.
      </p>
    </section>
  );
};

export default Experience;
