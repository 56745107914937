import React from "react";

const GetInTouch = () => {
  return (
    <div className="get-section">
      <div className="get-container">
        <h1 className="get-title" data-aos="fade-down">Get in Touch with Us</h1>
        <p className="get-description" data-aos="fade-up">
          We’d love to hear from you! Whether you have a question, need assistance, or want to learn more about Lipaworld, we’re here to help.
        </p>
      </div>
    </div>
  );
};

export default GetInTouch;
