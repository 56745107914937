import React from "react";
import RecognitionLogos from "./RecognitionLogos";

const Recognition = ({ topMargin = 0 }) => {
  const baseStyle = "col-md-2 col-lg-2 mt-10";

  return (
    <section
      id="content-2"
      className="content-2 wide-60 content-section division"
      style={{ paddingTop: "0px", marginTop: topMargin }}
    >
      <div className="container recognition recog" data-aos="fade-up">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-xl-12">
            <div className="section-title title-01 mb-70">
              <p className="rec">
                Trusted by Individuals And Teams At The World's Best Companies
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 reclogo">
            <div
              className={`rel img-block wow fadeInRight ${baseStyle} reclogo`}
            >
              <RecognitionLogos
                imageUrl={"mcdermott-will-emery.jpg"}
                description="Mcdermott rise recipient 2023"
              />
            </div>
            <div className={`${baseStyle}`}>
              <RecognitionLogos
                imageUrl={"money-2020.jpg"}
                description="2023 Money20/20"
              />
            </div>
            <div className={`${baseStyle}`}>
              <RecognitionLogos
                imageUrl={"black-ambition.jpg"}
                description="Black Ambition 2024 cohort"
              />
            </div>
            <div className={`${baseStyle} low`}>
              <RecognitionLogos
                imageUrl={"venturecrush.png"}
                description="Lowenstein Sandler Venture Crush 2024"
              />
            </div>
            <div className={`${baseStyle}`}>
              <RecognitionLogos
                imageUrl={"startup302.jpg"}
                description="Startup 302 pitch contest Fintech first place winners 2024"
              />
            </div>
            <div className={`${baseStyle}`}>
              <RecognitionLogos
                imageUrl={"western-union-foundation.jpg"}
                description="Western Union Foundation"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Recognition;
