import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Preference = ({ onClose }) => {
  const [fromCountry, setFromCountry] = useState('United States');
  const [toCountry, setToCountry] = useState('Nigeria');
  const [currency, setCurrency] = useState('USD');

  const countries = {
    'United States': '🇺🇸',
    'Canada': '🇨🇦',
    'United Kingdom': '🇬🇧',
    'Nigeria': '🇳🇬',
    'South Africa': '🇿🇦',
    'Ghana': '🇬🇭',
    'Kenya': '🇰🇪',
    'Zimbabwe': '🇿🇼'
  };

  const currencies = {
    'USD': '🇺🇸',
    'GBP': '🇬🇧',
    'NGN': '🇳🇬',
    'ZAR': '🇿🇦',
    'GHS': '🇬🇭',
    'KES': '🇰🇪',
    'ZWL': '🇿🇼'
  };

  return (
    <div className="preference-dropdown">
      <div className="preference-content">
        <div className="preference-section">
          <label className="preference-label" htmlFor="from">From</label>
          <div className="preference-input">
            <span className="flag-icon">{countries[fromCountry]}</span>
            <select 
              id="from" 
              className="preference-select"
              value={fromCountry}
              onChange={(e) => setFromCountry(e.target.value)}
            >
              <option>United States</option>
              <option>Canada</option>
              <option>United Kingdom</option>
            </select>
          </div>
        </div>

        <div className="preference-section">
          <label className="preference-label" htmlFor="to">To</label>
          <div className="preference-input">
            <span className="flag-icon">{countries[toCountry]}</span>
            <select 
              id="to" 
              className="preference-select"
              value={toCountry}
              onChange={(e) => setToCountry(e.target.value)}
            >
              <option>Nigeria</option>
              <option>South Africa</option>
              <option>Ghana</option>
              <option>Kenya</option>
              <option>Zimbabwe</option>
            </select>
          </div>
        </div>

        <div className="preference-section">
          <label className="preference-label" htmlFor="currency">Currency</label>
          <div className="preference-input">
            <span className="flag-icon">{currencies[currency]}</span>
            <select 
              id="currency" 
              className="preference-select"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option value="USD">USD - US Dollar</option>
              <option value="GBP">GBP - British Pound</option>
              <option value="NGN">NGN - Nigerian Naira</option>
              <option value="ZAR">ZAR - South African Rand</option>
              <option value="GHS">GHS - Ghanaian Cedi</option>
              <option value="KES">KES - Kenyan Shilling</option>
              <option value="ZWL">ZWL - Zimbabwean Dollar</option>
            </select>
          </div>
        </div>

        <button className="find-button" onClick={onClose}>
          Find Voucher
        </button>
      </div>
    </div>
  );
};

const NavShop = () => {
  const navigate = useNavigate();
  const [showPreference, setShowPreference] = useState(false);
  const dropdownRef = useRef(null);

  const handleCartClick = () => {
    navigate('/cart');
  };

  const togglePreference = () => {
    setShowPreference(!showPreference);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowPreference(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="navigation">
      <div className="filter-icon">
        <img src="/img/shop/sort.png" alt="Filter" width="22" height="22" />
      </div>
      <div className="search-bar">
        <div className="category-dropdown">
          <span className="category-text">Categories</span>
          <img src="/img/shop/arrow-down.png" alt="Dropdown" width="24" height="24" />
        </div>
        <img src="/img/shop/line.png" alt="Divider" className="divider" />
        <div className="search-input">
          <span className="search-placeholder">What are you looking for?</span>
          <img src="/img/shop/search-normal.png" alt="Search" width="24" height="24" className="si"/>
        </div>
      </div>
      
      <div className="preference-container" ref={dropdownRef}>
        <div className="language-switcherN" onClick={togglePreference}>
          <div className="country-flag">
            <span>🇺🇸</span>
            <span className="country-code">US</span>
          </div>
          <img src="/img/shop/arrow-right.png" alt="Arrow" width="24" height="24" />
          <div className="country-flag">
            <span>🇳🇬</span>
            <span className="country-code">NG</span>
          </div>
        </div>
        {showPreference && <Preference onClose={() => setShowPreference(false)} />}
      </div>

      <div 
        className="shopping-cart" 
        onClick={handleCartClick} 
        role="button" 
        tabIndex={0}
      >
        <img src="/img/shop/shopping-cart.png" alt="Shopping Cart" width="22" height="22" />
      </div>
    </div>
  );
};

export default NavShop;