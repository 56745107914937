import React from 'react';
import { Helmet } from 'react-helmet';
import Header from "../components/Header";
import OurStories from "../components/blog/OurStories";
import Fostering from "../components/blog/Fostering";
import AllBlogs from '../components/blog/AllBlogs'
import GetStarted from "../components/GetStarted";
import Footer from "../components/Footer";

const Blog = () => {
  return (
    <>  
   <Helmet>
    <title>Lipaworld - Blogs - Our Stories &amp; Latest News</title>
    <meta
      name="description"
      content="Stay updated with the latest news and stories from Lipaworld. Discover how we're revolutionizing digital payments and financial solutions across Africa."
    />
    <link rel="canonical" href="https://lipaworld.com/blogs" />
  </Helmet>
    <div className="min-h-screen">
        <div className="container mx-auto px-4">
          <Header />
          <main>
            <OurStories />
            <Fostering />
            <AllBlogs />
            <GetStarted />
            <Footer />
          </main>
        </div>
      </div></>
  );
};

export default Blog;
